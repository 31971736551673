<template>
  <b-card>
    <b-row class="table-filter">
      <b-col cols="2">
        <b-form-group
          id="input-from-date"
          label="Từ Ngày"
          label-for="from-date"
        >
          <datePicker v-model="filter_from" :config="dpOptions"></datePicker>
        </b-form-group>
      </b-col>

      <!-- filter date to -->
      <b-col cols="2">
        <b-form-group id="input-to-date" label="Đến Ngày" label-for="to-date">
          <datePicker v-model="filter_to" :config="dpOptions"></datePicker>
        </b-form-group>
      </b-col>

      <!-- Filter game  -->
      <b-col cols="2">
        <b-form-group
          id="input-game-filter"
          label="Đài"
          label-for="game-filter"
        >
          <b-form-select
            id="game-filter"
            v-model="filter_product"
            :options="productList"
          >
            <template v-slot:first>
              <b-form-select-option :value="null">Tất cả</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
      <!-- Query ticket_info -->
      <b-col cols="2">
        <b-form-group
          id="input-ticketInfo-filter"
          label="Bộ số"
          label-for="ticketInfo-filter"
        >
          <b-form-input id="ticketInfo-filter" v-model="query_ticket_info">
            <template v-slot:first>
              <b-form-input :value="''">Tất cả</b-form-input>
            </template>
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-button
          block
          variant="success"
          class="search-btn"
          @click="handleSearch"
          >TÌM KIẾM</b-button
        >
      </b-col>
      <b-col cols="2">
        <b-button
          block
          variant="primary"
          class="search-btn"
          @click="handleExportTraditionalTicket"
          >XUẤT EXCEL</b-button
        >
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="3" v-for="item of summary" v-bind:key="item.product_id">
        <h3>
          <b>{{ productName[item.product_id] }}</b>
        </h3>
        <div class="bg-secondary p-3 my-5">
          <div class="pr-3">
            <h5>
              Tổng vé : <b class="float-right">{{ item.total_stock }}</b>
            </h5>
          </div>
          <div class="pr-3">
            <h5>
              Đã bán :
              <b class="float-right">{{
                item.total_stock - item.total_remain
              }}</b>
            </h5>
          </div>
          <div class="pr-3">
            <h5>
              Còn lại : <b class="float-right">{{ item.total_remain }}</b>
            </h5>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-table
      striped
      :fields="headers"
      :items="items"
      :busy="isBusy"
      :current-page="currentPage"
      responsive
      bordered
      small
      class="elevation-1 data-table text-center"
    >
      <template v-slot:cell(lottery_time)="item">{{
        formatDate(item.value, "DD-MM-YYYY")
      }}</template>
      <template v-slot:cell(image_url)="item">
        <img
          :src="item.value"
          style="height: 50px"
          @click="openGallery(item.item.id)"
        />
        <LightBox
          :ref="'lightbox_' + item.item.id"
          :media="[{ thumb: item.value, src: item.value }]"
          :show-light-box="false"
          :show-thumbs="false"
          :disableScroll="false"
        ></LightBox>
      </template>
      <template v-slot:cell(stock_amount)="item">
        {{ item.value || 0 }}
      </template>
      <template v-slot:cell(sale_amount)="item"
        >{{ item.item.stock_amount - item.item.remain_amount }}
      </template>
      <template v-slot:cell(remain_amount)="item">
        {{ item.value || 0 }}
      </template>
      <template v-slot:cell(status_sale)="item">
        <b-form-checkbox
          class="customSwitch"
          switch
          size="lg"
          :checked="!!item.value"
          @click.native.prevent="handleChangeStatus(item)"
        >
          <b-badge v-if="item.value" variant="success">On sale</b-badge>
          <b-badge v-else variant="warning">Not on sale</b-badge>
        </b-form-checkbox>
      </template>
      <template v-slot:cell(group_status)="item">
        <b-badge class="mr-1" v-if="item.value === 1" variant="success"
          >CẶP NGUYÊN</b-badge
        >
        <b-badge class="mr-1" v-else-if="item.value === 0" variant="primary"
          >LẺ</b-badge
        >
      </template>
    </b-table>
    <b-row>
      <b-col cols="3">
        Hiển thị {{ (currentPage - 1) * perPage + 1 }}-{{
          currentPage * perPage > rows ? rows : currentPage * perPage
        }}
        trong {{ rows }}.
      </b-col>
      <b-col style="display: flex; justify-content: flex-end">
        <b-form inline style="margin-right: 20px; margin-bottom: 1rem">
          <label class="mr-sm-2" for="per-page">Số dòng: </label>
          <b-form-select
            id="per-page"
            v-model="perPage"
            size="sm"
            :options="[5, 10, 15, 20]"
            @change="fetchData"
          ></b-form-select>
        </b-form>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          align="right"
          v-on:input="fetchData"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import datePicker from "vue-bootstrap-datetimepicker";
import ApiService from "@/core/services/api.service.js";
import LightBox from "vue-image-lightbox";
import DateUtils from "@/utils/date.js";
// import axios from "axios";
export default {
  name: "Ticket",
  components: { datePicker, LightBox },
  data() {
    let { firstDate, lastDate } = DateUtils.getDate(new Date(), 7);
    return {
      isLoading: true,
      isBusy: false,
      statusList: [
        { value: 1, text: "Đã In", variant: "success" },
        { value: 2, text: "Đã Huỷ", variant: "danger" },
        { value: 0, text: "Chờ Xác Nhận", variant: "warning" },
      ],
      productList: [],
      headers: [
        { label: "Ngày Xổ", key: "lottery_time" },
        { label: "Đài", key: "product_name" },
        { label: "Bộ Số", key: "ticket_info" },
        { label: "Tổng Vé", key: "stock_amount" },
        { label: "Đã Bán", key: "sale_amount" },
        { label: "Tồn Kho", key: "remain_amount" },
        { label: "Ảnh", key: "image_url" },
        { label: "Trạng Thái", key: "status_sale" },
        { label: "Loại Vé", key: "group_status" },
      ],
      // Modal data
      items: [],
      modalTitle: "",
      // Modal validate
      rows: 0,
      perPage: 10,
      currentPage: 1,
      dialog: false,
      dpOptions: {
        format: "MM/DD/YYYY",
        sideBySide: true,
      },
      timeout: null,
      filter_product: null,
      filter_from: firstDate,
      filter_to: lastDate,
      query_ticket_info: "",
      editedItem: {},
      codeError: null,
      summary: {},
      productName: {},
    };
  },
  created() {
    this.fetchData();
    this.getAllProduct();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Kho" }]);
  },
  computed: {
    campaignId() {
      return this.$store.state.auth.campaignId;
    },
    resultSearch() {
      if (this.query_ticket_info) {
        return this.headers.filter((item) => {
          return this.query_ticket_info.every((v) =>
            item.ticket_info.includes(v),
          );
        });
      } else {
        return this.headers;
      }
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    editedItem: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.editedItem = newVal;
      }
    },
    productList(val) {
      let name = {};
      val.forEach((item) => {
        name[item.value] = item.text;
      });
      this.productName = name;
    },
  },
  methods: {
    openGallery(id) {
      this.$refs[`lightbox_${id}`].showImage(0);
    },
    handleExport() {
      this.exportData("/partners/exports/tickets", this.getQueryParams());
    },
    editItem(item) {
      this.editedItem = { ...item };
      this.dialog = true;
    },
    async handleChangeStatus(item) {
      ApiService.setHeader();
      ApiService.post("/storages/tickets", {
        ticket_id: item.item.id,
        status_sale: item.item.status_sale ? 0 : 1,
      })
        .then(() => {
          this.$bvToast.toast("Cập nhật trạng thái thành công.", {
            title: "Thông báo cập nhật",
            variant: "success",
            autoHideDelay: 5000,
            noCloseButton: true,
          });
          this.$set(
            this.items[item.index],
            "status_sale",
            !item.item.status_sale,
          );
        })
        .catch((errors) => {
          this.$bvToast.toast(`${errors}`, {
            variant: "danger",
            autoHideDelay: 3000,
            noCloseButton: true,
          });
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    handleSearch() {
      if (this.timeOut) {
        clearTimeout(this.timeOut);
      }

      this.timeOut = setTimeout(() => {
        this.fetchData();
      }, 400);
    },
    handleExportTraditionalTicket() {
      this.exportData(
        "/partners/exports/traditional-tickets",
        this.getQueryParamsExport(),
      );
    },
    getQueryParamsExport() {
      let to = "";
      if (this.filter_to) {
        to = new Date(this.filter_to);
        to.setDate(to.getDate() + 1);
      }
      let returnObj = {
        to,
        from: this.filter_from ? new Date(this.filter_from) : "",
      };
      return returnObj;
    },
    getQueryParams() {
      let to = "";
      if (this.filter_to) {
        to = new Date(this.filter_to);
        to.setDate(to.getDate() + 1);
      }
      let returnObj = {
        limit: this.perPage,
        offset: this.currentPage,
        from: this.filter_from ? new Date(this.filter_from) : "",
        to,
        product_id: this.filter_product || "",
        ticket_info: this.query_ticket_info || "",
      };

      return returnObj;
    },
    fetchData() {
      ApiService.query("/storages/remain", this.getQueryParams()).then(
        (response) => {
          this.items = response.data.data.ticketList;
          this.summary = response.data.data.summary || {};
          this.rows = response.data.data.total;
          this.isBusy = false;
        },
      );
    },
    getAllProduct() {
      ApiService.query("/products", {
        game_id: 8,
        limit: 99,
        offset: 0,
      }).then((response) => {
        this.productList = response?.data?.data?.productList?.map((item) => {
          return {
            text: item.name,
            value: item.id,
          };
        });
      });
    },
  },
};
</script>

<style lang="scss">
.customSwitch {
  display: inline-block;
  .custom-control-label {
    cursor: pointer;
  }
}
</style>
